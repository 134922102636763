// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-services-analytics-and-optimisation-tsx": () => import("./../../../src/pages/services/analytics-and-optimisation.tsx" /* webpackChunkName: "component---src-pages-services-analytics-and-optimisation-tsx" */),
  "component---src-pages-services-development-and-testing-tsx": () => import("./../../../src/pages/services/development-and-testing.tsx" /* webpackChunkName: "component---src-pages-services-development-and-testing-tsx" */),
  "component---src-pages-services-digital-strategy-tsx": () => import("./../../../src/pages/services/digital-strategy.tsx" /* webpackChunkName: "component---src-pages-services-digital-strategy-tsx" */),
  "component---src-pages-services-index-tsx": () => import("./../../../src/pages/services/index.tsx" /* webpackChunkName: "component---src-pages-services-index-tsx" */),
  "component---src-pages-services-research-and-design-tsx": () => import("./../../../src/pages/services/research-and-design.tsx" /* webpackChunkName: "component---src-pages-services-research-and-design-tsx" */),
  "component---src-templates-blog-category-tsx": () => import("./../../../src/templates/blogCategory.tsx" /* webpackChunkName: "component---src-templates-blog-category-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blogPost.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-project-category-tsx": () => import("./../../../src/templates/projectCategory.tsx" /* webpackChunkName: "component---src-templates-project-category-tsx" */),
  "component---src-templates-project-tsx": () => import("./../../../src/templates/project.tsx" /* webpackChunkName: "component---src-templates-project-tsx" */),
  "component---src-templates-projects-tsx": () => import("./../../../src/templates/projects.tsx" /* webpackChunkName: "component---src-templates-projects-tsx" */)
}

